import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pt-filter',
  templateUrl: './pt-filter.component.html',
  styleUrls: ['./pt-filter.component.css']
})
export class PtFilterComponent implements OnInit {
  
  @Input() filterVisibility: boolean = false;
  @Input() filterFields: any;
  @Input() filterInput: any;
  @Output() closeFilterEmitter = new EventEmitter();
  @Output() filterValue = new EventEmitter();
  @Output() resetFilter = new EventEmitter();
  
  form = new FormGroup({});
  model = {};
  // fields: FormlyFieldConfig[];
  submit(model) {
    //console.log(this.filterObj(this.filterInput, model));
    this.filterValue.emit(this.filterObj(this.filterInput, model))
  }

  constructor() { }

  ngOnInit(): void {
  }

  closeFilter(): void {
    this.closeFilterEmitter.emit('');
  }

  filter(){
    // this.filteredData = this.filterObj(this.data, this.formGroup.value);
    // this.filteredDataEventEmmiter.emit(this.filteredData)
  }

  filterObj(arr, obj) { 
    let info = [];
    let keys = Object.keys(obj);
    let values: any = Object.values(obj);
    for(let i = 0; i < keys.length; i++){
      if(values[i] != "" && values[i] != null){
        info.push({field: keys[i], value: values[i].toString()})
      }
    }
    return arr.filter(item => {
      return info.every(i => {
        if(item[i.field] && item[i.field].toLowerCase().includes(i.value.toLowerCase())){
          return item
        }
      });     
    }); 
  }

  reset(){
    this.resetFilter.emit(true)
    this.form.reset();
    this.closeFilter();
  }
}
