import { FormlyTextInputPreSection } from './shared/formly-custom-templates/formly-text-input-pre-section.type';
import { FormlyMultiselect } from './shared/formly-custom-templates/formly-multiselect.type';
import { FormlyMultiFileUpload } from './shared/formly-custom-templates/formly-multi-file-upload.type';
import { FormlyMultiImageUpload } from './shared/formly-custom-templates/formly-multi-image-upload.type';
import { FormlySelectSearch } from './shared/formly-custom-templates/formly-select-search.type';
import { FormlySwitch } from './shared/formly-custom-templates/formly-switch.type';
import { FormlyTwoFieldInput } from './shared/formly-custom-templates/formly-two-field-input.type';
import { FormlyTextInput } from './shared/formly-custom-templates/formly-text-input.type';
import { FormlyPassword } from './shared/formly-custom-templates/formly-password.type';
import { FormlyFieldConfig, FieldType } from '@ngx-formly/core';
import { FormlyReadOnly } from './shared/formly-custom-templates/formly-read-only.type';
import { FormlyMultiselectCheckbox } from './shared/formly-custom-templates/formly-multiselect-checkbox.type';
import { FormlyCkEditor } from './shared/formly-custom-templates/formly-ck-editor-input.type';
import { FormlyMulticheckbox } from './shared/formly-custom-templates/formly-multicheckbox.type';

function requiredValidationMessage(err, field: FormlyFieldConfig){
    if(field.templateOptions.label)
        return `Per favore, inserisci ${field.templateOptions.label}`
    else 
        return `Per favore, inserisci ${field.key.charAt(0).toUpperCase() + field.key.slice(1)}`
}
export const formlyConfig = {
    wrappers: [],
    types: [
      { name: 'formly-password', component: FormlyPassword, wrappers: ['form-field'] },
      { name: 'formly-text-input', component: FormlyTextInput, wrappers: ['form-field'] },
      { name: 'formly-ck-editor', component: FormlyCkEditor, wrappers: ['form-field'] },
      { name: 'formly-switch', component: FormlySwitch},
      { name: 'formly-read-only', component: FormlyReadOnly, wrappers: ['form-field'] },
      { name: 'formly-images-upload', component: FormlyMultiImageUpload, wrappers: ['form-field'] },
      { name: 'formly-files-upload', component: FormlyMultiFileUpload},
      { name: 'formly-select-search', component: FormlySelectSearch, wrappers: ['form-field'] },
      { name: 'formly-multiselect-checkbox', component: FormlyMultiselectCheckbox, wrappers: ['form-field'] },
      { name: 'formly-multiselect', component: FormlyMultiselect, wrappers: ['form-field'] },
      { name: 'formly-multicheckbox', component: FormlyMulticheckbox, wrappers: ['form-field'] },
      { name: 'formly-text-input-pre-section', component: FormlyTextInputPreSection, wrappers: ['form-field'] },

      { 
        name: 'formly-two-field-input', 
        component: FormlyTwoFieldInput, 
        wrappers: ['form-field'],
        defaultOptions: {
          fieldGroup: [
            {type : 'input', key: 'firstInput'},
            {type : 'input', key: 'secondInput'}
          ]
        }
      },
    ],
    /*validationMessages: [
      { name: 'required', message: requiredValidationMessage }
    ]*/
  }
  
export const deleteModalMessage = `Sei sicuro di voler eliminare l'elemento?`;
