import { FormlyTextInputPreSection } from './shared/formly-custom-templates/formly-text-input-pre-section.type';
import {FormlyMultiselect} from './shared/formly-custom-templates/formly-multiselect.type';
import {FormlyMultiFileUpload} from './shared/formly-custom-templates/formly-multi-file-upload.type';
import {FormlyMultiImageUpload} from './shared/formly-custom-templates/formly-multi-image-upload.type';
import {FormlySelectSearch} from './shared/formly-custom-templates/formly-select-search.type';
import {FormlySwitch} from './shared/formly-custom-templates/formly-switch.type';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyTwoFieldInput} from './shared/formly-custom-templates/formly-two-field-input.type';
import {FormlyTextInput} from './shared/formly-custom-templates/formly-text-input.type';
import {FormlyPassword} from './shared/formly-custom-templates/formly-password.type';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ZorroModule} from './zorro/zorro.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IconsProviderModule} from './icons-provider.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import {en_US} from 'ng-zorro-antd/i18n';
import {HashLocationStrategy, LocationStrategy, registerLocaleData, PathLocationStrategy} from '@angular/common';
import en from '@angular/common/locales/en';

import {SharedModule} from './shared/shared.module';
import {FormlyNgZorroAntdModule} from '@ngx-formly/ng-zorro-antd';
import {formlyConfig} from './app.constants';
import {FormlyReadOnly} from './shared/formly-custom-templates/formly-read-only.type';
import {FormlyMultiselectCheckbox} from './shared/formly-custom-templates/formly-multiselect-checkbox.type';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyCkEditor} from './shared/formly-custom-templates/formly-ck-editor-input.type';
import {CKEditorModule} from 'ckeditor4-angular';
import {FormlyMulticheckbox} from './shared/formly-custom-templates/formly-multicheckbox.type';

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent, FormlyPassword, FormlyTextInput,
        FormlyTwoFieldInput, FormlyReadOnly, FormlyMultiselectCheckbox,
        FormlySwitch, FormlySelectSearch, FormlyMultiImageUpload,
        FormlyCkEditor, FormlyMultiFileUpload, FormlyMultiselect, FormlyMulticheckbox, FormlyTextInputPreSection
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        IconsProviderModule,
        FormlyNgZorroAntdModule,
        FormlyModule.forRoot(formlyConfig),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        NgSelectModule,
        CKEditorModule,
        ZorroModule,


    ],
    providers: [{provide: NZ_I18N, useValue: en_US}],
    bootstrap: [AppComponent],
    exports: [FormlyPassword, FormlyTextInput, FormlyTwoFieldInput,
        FormlyReadOnly, FormlyMultiselectCheckbox, FormlySwitch,
        FormlySelectSearch, FormlyMultiImageUpload, FormlyCkEditor,
        FormlyMultiFileUpload, FormlyMultiselect, FormlyMulticheckbox, FormlyTextInputPreSection
    ]
})
export class AppModule {
}
