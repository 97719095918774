import { AuthGuard } from './core/services/guards/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: '', loadChildren: () => import(`./frontend/frontend.module`).then(m => m.FrontendModule) },
  {path: 'admin', loadChildren: () => import(`./backend/backend.module`).then(m => m.BackendModule), canActivate: [AuthGuard], data: {redirect: ['/auth/login']}},
  {path: 'auth', loadChildren: () => import(`./core/core.module`).then(m => m.CoreModule)},
  //{ path: '**', redirectTo: 'admin', pathMatch: 'full' }
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
